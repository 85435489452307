import React from 'react';
import styled, { withTheme } from 'styled-components';
import get from 'lodash/get';
import { Row, Col, Form, message } from 'antd';
import { EMAIL_REGEX, PHONE_REGEX } from '../constants/pattern';
import { Title, Input, Link } from '../common/components';
import { sendMail } from '../utils/sendMail';

type Props = {
  data: any;
  form: any;
  theme: any;
  emailDestination: string;
  emailSubject: string;
};

const FormContainer = styled(Row)`
  padding: 40px 36px 40px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

const FormTitle = styled(Title)`
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 28px !important;
`;

const FormInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.color.background.main};
  height: 50px;
  overflow: hidden;
  width: 100%;
  margin-top: 15px;
  margin-right: 15px;
  justify-content: space-between;
  * {
    font-family: ${props => props.theme.fontFamily};
  }
`;

const FormInputTextAreaContainer = styled.div`
  background-color: ${props => props.theme.color.background.main};
  padding: 0px;
  margin-top: 15px;
`;

const FormCol = styled(Col)`
  text-align: center;
`;

const SubmitButton = styled.a`
  color: ${props => props.theme.color.text.light};
  font-size: 18px !important;
  text-decoration: underline;
`;

const SubmitButtonRow = styled(Row)`
  margin-top: 10px;
`;

class ContactForm extends React.Component<Props, any> {
  state = {
    loading: false,

    // Input
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  clearForm() {
    this.setState({
      loading: false,

      // Input
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  }

  buildEmailHtml = values => `<html>
  <head>
  </head>
  <body>
  <table id="box" cellpadding="0" cellspacing="0" border="0" align="center" style="width: 100%; border-collapse: collapse; margin: 50px auto; font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif; font-size: 15px;" bgcolor="white" width="100%">
    <tbody>
      <tr>
        <td>
          <table id="summary-box" style="border-spacing: 20px 0;">
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Name</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${get(
                values,
                'name',
                'n/a'
              )}</p></td>
            </tr>
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Email</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${get(
                values,
                'email',
                'n/a'
              )}</p></td>
            </tr>
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Phone number</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${get(
                values,
                'phone',
                'n/a'
              )}</p></td>
            </tr>
            <tr>
              <td class="property-name"><p style="font-weight: bold; font-size: 14px;">Message</p><p style="font-weight: bold; font-size: 14px;"></p></td>
              <td class="property-value"><p style="font-size: 13px;">${get(
                values,
                'message',
                'n/a'
              )}</p></td>
            </tr>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  </body>
  </html>`;

  async onSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    this.props.form.validateFields(async (err, values) => {
      try {
        if (err) {
          throw new Error('Sent message fail');
        }

        const destinations = get(this.props, 'emailDestination');
        const subject =
          get(this.props, 'emailSubject') +
          ' - ' +
          get(this.state, 'email', 'n/a');

        await sendMail(
          destinations,
          subject,
          this.buildEmailHtml(this.state),
          `${get(this.state, 'name')} contact via web...`
        );

        this.clearForm();

        message.success(get(this.props.data, 'onSuccessMessage'));
      } catch (error) {
        message.error(get(this.props.data, 'onErrorMessage'));
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { data, theme, form } = this.props;
    const { loading } = this.state;

    const title = get(data, 'title');
    const name = get(data, 'name');
    const email = get(data, 'email');
    const phone = get(data, 'phone');
    const message = get(data, 'message');

    const submit = get(data, 'submit');

    return (
      <FormContainer type="flex" align="middle">
        <FormCol span={24}>
          {title && (
            <FormTitle level={2} color={theme.color.primary.light}>
              {title}
            </FormTitle>
          )}
          <Form>
            <FormItem>
              <FormInputContainer>
                {form.getFieldDecorator('name', {
                  rules: [
                    { required: true, message: 'Please input your name!' },
                    { min: 3, message: 'Your name is too short' },
                  ],
                })(
                  <Input.Container>
                    <Input
                      placeholder={name}
                      disabled={loading}
                      value={this.state.name}
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </Input.Container>
                )}
              </FormInputContainer>
            </FormItem>
            <Row>
              <Col md={12}>
                <FormItem>
                  <FormInputContainer>
                    {form.getFieldDecorator('email', {
                      rules: [
                        { required: true, message: 'Please input your email!' },
                        {
                          pattern: EMAIL_REGEX,
                          message: 'Your email is invalid!',
                        },
                      ],
                    })(
                      <Input.Container>
                        <Input
                          type="email"
                          placeholder={email}
                          disabled={loading}
                          value={this.state.email}
                          onChange={e =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </Input.Container>
                    )}
                  </FormInputContainer>
                </FormItem>
              </Col>
              <Col md={{ span: 11, offset: 1 }}>
                <FormItem>
                  <FormInputContainer>
                    {form.getFieldDecorator('phone', {
                      rules: [
                        { required: true, message: 'Please input your phone!' },
                        { min: 9, message: 'Your phone number is too short' },
                        {
                          pattern: PHONE_REGEX,
                          message: 'Your phone number is invalid!',
                        },
                      ],
                    })(
                      <Input.Container>
                        <Input
                          type="tel"
                          placeholder={phone}
                          disabled={loading}
                          value={this.state.phone}
                          onChange={e =>
                            this.setState({ phone: e.target.value })
                          }
                        />
                      </Input.Container>
                    )}
                  </FormInputContainer>
                </FormItem>
              </Col>
            </Row>
            <FormItem>
              <FormInputTextAreaContainer>
                {form.getFieldDecorator('message', {
                  rules: [
                    { required: true, message: 'Please input your message!' },
                  ],
                })(
                  <Input.TextAreaContainer>
                    <Input.TextArea
                      autosize={{ minRows: 8, maxRows: 8 }}
                      placeholder={message}
                      style={{ resize: 'none' }}
                      disabled={loading}
                      value={this.state.message}
                      onChange={e => this.setState({ message: e.target.value })}
                    />
                  </Input.TextAreaContainer>
                )}
              </FormInputTextAreaContainer>
            </FormItem>
            <SubmitButtonRow>
              <SubmitButton onClick={this.onSubmit.bind(this)}>
                {submit}
              </SubmitButton>
            </SubmitButtonRow>
          </Form>
        </FormCol>
      </FormContainer>
    );
  }
}

const WrappedContactForm = Form.create<Props>({ name: 'contact-form' })(
  ContactForm
);

export default withTheme(WrappedContactForm);
