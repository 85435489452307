import React from 'react';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import get from 'lodash/get';
import { Row, Col } from 'antd';
import { SectionContainer, FlowerPattern } from '../common/components';

import flowerGold2 from '../assets/flower-2-gold.svg';

import ContactForm from './ContactForm';
import ContactMap from './ContactMap';


interface Props {
  data: any;
  theme: any;
  lang: any;
}

const Container = styled(SectionContainer)`
  margin-top: 35px;
  margin-bottom: 35px;
  padding-left: 20px;
  padding-right: 20px;

  ${breakpoint('xl')`
    padding-left: 0;
    padding-right: 0;
  `}
`;

const InnerContainer = styled(Row)`
  text-align: left;
`;

const MapCol = styled(Col)`
    padding-top: 20px;
    z-index: 1;
`;


class ContactFormSection extends React.Component<Props, any> {
  render() {
    const { data, theme, lang } = this.props;

    const form = get(data, 'primary.form.document[0].data');
    const map = get(data, 'primary.map.document[0].data');
    const emailSubject = get(data, 'primary.emailSubject');
    const emailDestination = get(data, 'primary.emailDestination');
    const requestLimousineLink = get(data, 'primary.requestLimousineLink');
    const requestLimousineText = get(data, 'primary.requestLimousineText');

    return (
      <Container>
        <FlowerPattern
          src={flowerGold2}
          height="100px"
          width="auto"
          right="-100px"
          bottom="79%"
          opacity={0.6}
        />
        <InnerContainer type="flex">
          <MapCol xs={form ? 24 : 0} md={{ span: form ? 12 : 0 }}>
            <ContactMap width="100%" height="250" data={map} lang={lang} limousineData={data} requestLimousineLink={requestLimousineLink} requestLimousineText={requestLimousineText} />
          </MapCol>
          <Col xs={24} md={{ span: form ? 11 : 24, offset: form ? 1 : 0 }}>
            <ContactForm data={form} emailSubject={emailSubject} emailDestination={emailDestination} />
          </Col>
        </InnerContainer>
      </Container>
    );
  }
}

export default withTheme(ContactFormSection);
