import React, { Component } from 'react';
import styled, { withTheme, css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col } from 'antd';
import { Title, FlowerPattern } from '../common/components';
import * as flowerGold from '../assets/flower-1-gold.svg';

interface Props {
    title?: string;
    theme: any;
    backgrounds?: any;
    className?: any;
    height?: any;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
const InnerContainer = styled(Row)`
  position: relative;
  min-height: ${props => (props.height ? props.height : 250)}px;
  ${props =>
        props.height &&
        css`
      height: ${props => props.height}px;
    `}
  width: 100%;
  /* margin: 20px 40px; */

  max-width: 900px;
  ${breakpoint('md')`
    max-width: 1000px;
  `}
`;

const DescriptionCol = styled(Col)`
  text-align: center;
`;
const DescriptionColSpacer = styled(DescriptionCol)`
  margin-bottom: 1em;
`;

const TitleText = styled(Title)`
  color: ${props => props.theme.color.primary.main} !important;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
`;

class SectionContainer extends Component<Props, any> {
    render() {
        const {
            title,
            className,
            height,
        } = this.props;

        const backgrounds = [<FlowerPattern
            key="bg_1"
            src={flowerGold}
            height={'200px'}
            width={'auto'}
            right={'95%'}
            bottom={'-150px'}
            opacity={0.6}
        />];

        return (
            <Container className={className}>
                <InnerContainer height={height}>
                    {title && (
                        <DescriptionCol xs={24}>
                            <TitleText>{title}</TitleText>
                        </DescriptionCol>
                    )}
                </InnerContainer>
                {backgrounds && backgrounds.map(bg => bg)}
            </Container>
        );
    }
}

export default withTheme(SectionContainer);
