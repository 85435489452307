import React from 'react';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import get from 'lodash/get';
import { Row, Col } from 'antd';
import { Title, Text, SectionContainer, FlowerPattern } from '../common/components';
import { theme } from '../common/theme';

import * as flowerGold2 from '../assets/flower-2-gold.svg';
import * as flowerGold3 from '../assets/flower-3-gold.svg';

type Props = {
    data: any;
    theme: any;
};

const InfoContainer = styled(SectionContainer)`
    background-color: ${props => props.theme.color.background.black};
    padding: 3%;
 
    @media (max-width: ${theme.breakpoints.sm}px) {
        height: 48vw !important;
    }

    // Iphone 5 Only
    @media (min-width: 568px) and (max-width: 568px) {
        height: 30vw !important;
    }
`;

const ContextContainer = styled.div`
    margin-bottom: 15px;
`;

const InfoText = styled(Text)`
    text-align: left;

    @media (max-width: ${theme.breakpoints.sm}px) {
        font-size: 1.05rem !important;
    }
`;

const InfoTitle = styled(Title)`
    font-weight: bold !important;
`;

const InfoTitleHideOnMobile = styled(InfoTitle)`

    @media (max-width: ${theme.breakpoints.sm}px) {
        display: none !important;
    }
`;

const RowHideOnMobile = styled(Row)`
    display: none !important;
    
    ${breakpoint('sm')`
        display: block !important;
    `}  

    ${breakpoint('md')`
        margin-top: 15px;
    `}  
`;

const InfoSectionTitle = styled(Title)`

    ${breakpoint('md')`
        margin-bottom: 30px !important;
    `}  

    @media (max-width: ${theme.breakpoints.sm}px) {
        padding-top: 22vw !important;
    }

    // Iphone 5 Only
    @media (min-width: 568px) and (max-width: 568px) {
        padding-top: 15vw !important;
    }
`;

class ContactInfo extends React.Component<Props, any> {
    render() {
        const { data, theme } = this.props;

        const title = get(data, 'primary.info_title');

        // Reservation
        const reserveTitle = get(data, 'primary.reserve_title');
        const reserveEmail = get(data, 'primary.reserve_email');
        const reserveTel = get(data, 'primary.reserve_tel');

        // Agent
        const agentTitle = get(data, 'primary.agent_title');
        const agentEmail = get(data, 'primary.agent_email');
        const agentTel = get(data, 'primary.agent_tel');

        // Marketing
        const marketingTitle = get(data, 'primary.marketing_title');
        const marketingEmail = get(data, 'primary.marketing_email');
        const marketingTel = get(data, 'primary.marketing_tel');

        return (
            <SectionContainer>
                <FlowerPattern
                    src={flowerGold2}
                    height={'180px'}
                    width={'auto'}
                    right={'105%'}
                    bottom={'10%'}
                    opacity={0.6}
                />
                <FlowerPattern
                    src={flowerGold3}
                    height={'180px'}
                    width={'auto'}
                    right={'-13%'}
                    bottom={'50%'}
                    opacity={0.6}
                />
                <InfoContainer>
                    <InfoSectionTitle level={2} color={theme.color.primary.light}>{title.toUpperCase()}</InfoSectionTitle>
                    <Row>
                        <ContextContainer>
                            <InfoTitleHideOnMobile level={4}>{reserveTitle.toUpperCase()}</InfoTitleHideOnMobile>
                            <InfoText small>Tel: {reserveTel}</InfoText><br />
                            <InfoText small>Email: {reserveEmail}</InfoText>
                        </ContextContainer>
                    </Row>
                    <RowHideOnMobile>
                        <Col xs={24} md={12}>
                            <ContextContainer>
                                <InfoTitle level={4}>{agentTitle.toUpperCase()}</InfoTitle>
                                <InfoText small>Tel: {agentTel}</InfoText><br />
                                <InfoText small>Email: {agentEmail}</InfoText>
                            </ContextContainer>
                        </Col>
                        <Col xs={24} md={12}>
                            <ContextContainer>
                                <InfoTitle level={4}>{marketingTitle.toUpperCase()}</InfoTitle>
                                <InfoText small>Tel: {marketingTel}</InfoText><br />
                                <InfoText small>Email: {marketingEmail}</InfoText>
                            </ContextContainer>
                        </Col>
                    </RowHideOnMobile>
                </InfoContainer>
            </SectionContainer>
        );
    }
}

export default withTheme(ContactInfo);
