import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import { PageHero } from '../common/components';
import ContactIntroduction from './ContactIntroduction';
import ContactInfo from './ContactInfo';
import ContactFormSection from './ContactFormSection';

interface Props {
  data: any;
  location: any;
}

class Contact extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');
    const pageTitle = get(data, 'page.data.title');

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body)
          && body.map((slice) => {
            switch (slice.__typename) {
              case 'PrismicContactBodyHero':
                return (
                  <div>
                    <PageHero key={slice.id + slice.__typename} data={slice} />
                    <ContactIntroduction
                      key={`${slice.id + slice.__typename}Introduction`}
                      title={pageTitle}
                      height={50}
                    />
                  </div>
                );
              case 'PrismicContactBodyFormSection':
                return (
                  <ContactFormSection
                    key={slice.id + slice.__typename}
                    data={slice}
                    lang={get(data, 'page.lang', 'en-us')}
                  />
                );
              case 'PrismicContactBodyInfoSection':
                return <ContactInfo key={slice.id + slice.__typename} data={slice} />;

              default:
                return null;
            }
          })}
      </Layout>
    );
  }
}

export default Contact;

// Query metadata for the homepage
export const query = graphql`
  query ContactQuery($slug: String!) {
    page: prismicContact(id: { eq: $slug }) {
      ...Contact
    }
  }
`;
