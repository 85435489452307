import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Title, Paragraph, Link } from '../common/components';
import get from 'lodash/get';
import { getInternalUrl } from '../utils';
import { RequestLimousineForm } from '../common/components';

interface Props {
    height: any;
    width: any;
    theme: any;
    data: any;
    lang: any;
    requestLimousineLink: string;
    requestLimousineText: string;
    limousineData: any
}


const MapContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const RequestLimousineLink = styled(Link)`
    font-size: calc(0.875rem + ((1vw - 4.8px) * 0.2778)) !important;
    font-weight: bold;
`;


class ContactMap extends React.Component<Props, any> {

    constructor(props) {
        super(props);

        this.state = {
            showBooking: false
        }
        this.triggerPopup = this.triggerPopup.bind(this);
        this.onClose = this.onClose.bind(this);

    }

    triggerPopup() {
        this.setState({ showBooking: !this.state.showBooking });
    }

    onClose(){
        this.setState({ showBooking: false });
    }

    render() {
        const { height, width, theme, data, lang, requestLimousineLink, requestLimousineText, limousineData } = this.props;
        const { showBooking } = this.state;

        const mapUrl = get(data, 'mapUrl');
        const mapTitle = get(data, 'mapTitle');
        const mapDescription = get(data, 'mapDescription');

        return (
            <div> 
                <MapContainer>
                    <iframe src={mapUrl} width={width || 300} height={height || 250} frameBorder="0" allowFullScreen></iframe>
                </MapContainer>
                <Title level={3} color={theme.color.primary.light}>{mapTitle.toUpperCase()}</Title><br />
                <Paragraph small>
                    {mapDescription}
                </Paragraph>
                <RequestLimousineForm showBooking={showBooking} onClose={this.onClose} data={limousineData}/>
                <RequestLimousineLink
                   onClick={this.triggerPopup}>{requestLimousineText}</RequestLimousineLink>
            </div>
        );
    }
}

export default withTheme(ContactMap);